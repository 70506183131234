import { Alignment, Fit, Layout, useRive } from "@rive-app/react-canvas";
import { AnimatingArrow } from "~/components/primitives/AnimatingArrow";
import { LinkButton } from "~/components/primitives/Buttons";
import { GridContent, GridRow } from "~/components/primitives/GridRow";
import { Section } from "~/components/primitives/Section";
import { Header3, Header4 } from "~/components/primitives/text/Header";
import { Paragraph } from "~/components/primitives/text/Paragraph";

export function GetStartedSection({
  enterpriseCopy = false,
  hidePattern = false,
  animation,
}: {
  enterpriseCopy?: boolean;
  hidePattern?: boolean;
  animation?: string;
}) {
  return (
    <Section id="get-started">
      {!hidePattern && (
        <GridRow removeBorderT>
          <div className="col-span-1 h-24 w-full bg-pattern-crosses  sm:col-span-12" />
        </GridRow>
      )}
      <GridRow
        autoGridLines={false}
        autoResponsiveGrid={false}
        removeBordersX
        className="grid-cols-1 border-l border-r border-grid-dimmed py-24 sm:grid-cols-1 md:grid-cols-12 md:border-l-0 md:bg-visible-grid-2 md:py-48 lg:bg-visible-grid-4"
      >
        <GridContent className="col-span-1 md:col-span-6" justifyCenter>
          <div className="flex max-w-lg flex-col gap-6 pr-3">
            <Header3 variant="large">Ready to start building?</Header3>
            <Paragraph variant="extra-large" spacing>
              Build and deploy your first task in 3 mins with no timeouts and no
              infrastructure to manage.
            </Paragraph>
            <LinkButton
              variant="primary/large"
              TrailingIcon={() => (
                <AnimatingArrow variant="large" theme="dark" />
              )}
              to="https://cloud.trigger.dev"
              trailingIconClassName="group-hover:translate-x-1 duration-200"
              className="group"
              target="_self"
            >
              Get started for free
            </LinkButton>
          </div>
        </GridContent>
        <GridContent className="pt-12 md:col-span-3 md:pt-0" justifyCenter>
          {enterpriseCopy ? (
            <AnimatedSection
              animation="/animations/office-icon.riv"
              title="Enterprise"
              externalLink={false}
              description="Custom plans and support for your enterprise needs."
              to="/contact"
              linkText="Contact us"
            />
          ) : (
            <AnimatedSection
              animation="/animations/pricing-tag.riv"
              title="Simple pricing"
              externalLink={false}
              description="Only pay for what you use and scale with your needs."
              to="/pricing"
              linkText="Explore pricing"
            />
          )}
        </GridContent>
        <GridContent className="pt-12 md:col-span-3 md:pt-0" justifyCenter>
          <AnimatedSection
            animation="/animations/self-hosting-icon.riv"
            title="Self-host"
            externalLink={true}
            description="Trigger.dev is open source and self-hostable."
            to="https://trigger.dev/docs/v3/open-source-self-hosting"
            linkText="Self-hosting docs"
          />
        </GridContent>
      </GridRow>
    </Section>
  );
}

function AnimatedSection({
  title,
  description,
  to,
  linkText,
  animation,
  externalLink,
}: {
  animation: string;
  title: string;
  description: string;
  to: string;
  linkText: string;
  externalLink?: boolean;
}) {
  const { rive, RiveComponent } = useRive({
    src: animation,
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.Center,
    }),
    autoplay: false,
  });

  return (
    <div
      onMouseEnter={() => {
        rive && rive.play("hover");
      }}
      onMouseLeave={() => rive && rive.play("default")}
      className="flex flex-col gap-3"
    >
      <div>
        <RiveComponent className="size-8" />
      </div>

      <Header4 variant="small">{title}</Header4>
      <Paragraph>{description}</Paragraph>
      <LinkButton
        variant="minimal/small"
        to={to}
        TrailingIcon={() => <AnimatingArrow />}
        trailingIconClassName="group-hover:translate-x-1 duration-200"
        className="px-0"
        target={externalLink ? "_blank" : "_self"}
      >
        {linkText}
      </LinkButton>
    </div>
  );
}
